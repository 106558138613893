import React, { ReactElement, useRef } from 'react';
import * as Styled from './styles';
import { TwoColSection } from '../../components/TwoColSection/TwoColSection';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { css } from '@emotion/react';

export const AppVersionsSection = (): ReactElement => {
  const accordionRef = useRef(null);
  const { isVisible } = useVisibilityDetection(accordionRef);

  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper isVisible={isVisible} ref={accordionRef}>
        <TwoColSection
          styles={css`
            max-width: 1170px;
          `}
          heading={
            <Styled.Heading>
              <Styled.HeadingTitle>Take a minute and get SketchWow now!</Styled.HeadingTitle>
            </Styled.Heading>
          }
          leftColumn={
            <Styled.LeftColumn>
              <Styled.Illustration
                src="/sketch-wow-box.webp"
                srcSet="/sketch-wow-box.webp 1x, /sketch-wow-box@2x.webp 2x"
                alt="SketchWow box illustration"
                loading="lazy"
              />
            </Styled.LeftColumn>
          }
          rightColumn={
            <Styled.RightColumn>
              <Styled.Content>
                <h3>Standard Or Pro?</h3>
                <p>
                  We offer two account plans (Standard, Pro). Click the “Get SketchWow” button for a side-by-side comparison, so you can
                  choose the account plan that best fits your needs.
                </p>
                <h3>30-Day Money-Back Guarantee</h3>
                <p>
                  Go take SketchWow for a spin. You've got a full month to make up your mind. We think you'll fall in love with how simple &
                  fun it is to create amazing, engaging sketches.
                </p>
                <p>
                  If for some reason you are not completely "Wow'd" by SketchWow within the first 30 days, simply email us at
                  support@sketchwow.com. You'll get a prompt and courteous refund. We want you to be happy using SketchWow.
                </p>
              </Styled.Content>
            </Styled.RightColumn>
          }
        />
      </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};
