import React, { ReactElement, useRef } from 'react';
import * as Styled from './styles';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';

export const Illustrations = (): ReactElement => {
  const illustrationsRef = useRef(null);
  const { isVisible } = useVisibilityDetection(illustrationsRef);

  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper ref={illustrationsRef} isVisible={isVisible}>
        <Styled.IllustrationWrapper>
          <Styled.Arrow />
          <Styled.Image
            width={1181}
            src="illustration.webp"
            srcSet="illustration.webp 1x, illustration@2x.webp 2x"
            alt="Illustration"
            loading="lazy"
          />
        </Styled.IllustrationWrapper>
      </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};
