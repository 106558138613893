import styled from '@emotion/styled';
import { Visibility } from '../../types';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import topBackground from '../../assets/backgrounds/navy-blue-top.svg';
import bottomBackground from '../../assets/backgrounds/navy-blue-bottom.svg';

const TOP_BACKGROUND_HEIGHT = '1.25vw';
const BOTTOM_BACKGROUND_HEIGHT = '1.25vw';

export const SafariWrapper = styled.div`
  max-width: 100%;
  margin-bottom: -${BOTTOM_BACKGROUND_HEIGHT};
  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  overflow: hidden;
  position: relative;
  z-index: 0;
`;

export const Wrapper = styled.section<Visibility>`
  opacity: 0;
  padding: 98px ${dimensions.containerPadding}px 13px ${dimensions.containerPadding}px;
  position: relative;
  z-index: 2;
  background-color: ${colors.navyBlue};
  color: ${colors.white};
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  @media screen and (max-width: ${breakpoints.sm}px) {
    padding-right: 0;
    padding-left: 0;
  }

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
    transform: translateY(0);
  `}

  &:before,
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    background-size: contain;
  }
  &:before {
    height: ${TOP_BACKGROUND_HEIGHT};
    top: calc(-${TOP_BACKGROUND_HEIGHT} + 2px);
    background: url(${topBackground}) no-repeat center 0;

    @media screen and (max-width: 1024px) {
      margin-top: 1px;
      transform: scale(1.04);
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.02);
    }
  }
  &:after {
    height: ${BOTTOM_BACKGROUND_HEIGHT};
    bottom: -${BOTTOM_BACKGROUND_HEIGHT};
    background: url(${bottomBackground}) no-repeat center 0;

    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const Heading = styled.header`
  width: 100%;
  text-align: center;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-bottom: 30px;
  }
`;

export const HeadingTitle = styled.h2`
  margin: 0;
  color: ${colors.white};
  font-size: ${dimensions.fontSize.heading}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
`;

export const RightColumn = styled.div`
  margin-left: -6px;

  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  color: ${colors.white};
  font-size: ${dimensions.fontSize.regular}px;
  line-height: 1.63;

  h3 {
    color: inherit;

    & + p {
      margin-top: -13px;
    }
  }

  p {
    & + h3 {
      margin-top: -3px;
    }
  }

  p,
  h3 {
    margin: 26px 0;
  }

  h3 {
    font-weight: 900;
    font-size: ${dimensions.fontSize.medium}px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const LeftColumn = styled.figure`
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin: 0;
    padding: 0;
  }
`;

export const Illustration = styled.img`
  transform: translate(-112px, 31px);

  @media screen and (max-width: ${breakpoints.sm}px) {
    max-width: 100%;
    transform: none;
  }
`;
