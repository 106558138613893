import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import ArrowIllustration from '../../assets/doodles/illustrations-arrow.inline.svg';
import illustration from '../../assets/illustration-background.svg';
import backgroundTop from '../../assets/backgrounds/biscuit-top.svg';
import backgroundBottom from '../../assets/backgrounds/biscuit-bottom.svg';
import { Visibility } from '../../types';

const TOP_BACKGROUND_HEIGHT = '1.45vw';
const BOTTOM_BACKGROUND_HEIGHT = '1.88vw';

export const SafariWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const Wrapper = styled.section<Visibility>`
  opacity: 0;
  height: 599px;
  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  margin-top: calc(64px + ${TOP_BACKGROUND_HEIGHT});
  margin-bottom: calc(221px + ${BOTTOM_BACKGROUND_HEIGHT});
  padding: 0 ${dimensions.containerPadding}px;
  position: relative;
  z-index: 2;
  background-color: ${colors.biscuit};
  background-size: cover;
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
    transform: translateY(0);
  `}

  &:before,
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    background-size: contain;
  }
  &:before {
    height: ${TOP_BACKGROUND_HEIGHT};
    top: calc(-${TOP_BACKGROUND_HEIGHT} + 1px);
    background: url(${backgroundTop}) no-repeat center 0;

    @media screen and (max-width: ${breakpoints.lg}px) {
      margin-top: 1px;
      transform: scale(1.1);
    }
  }
  &:after {
    height: ${BOTTOM_BACKGROUND_HEIGHT};
    bottom: -${BOTTOM_BACKGROUND_HEIGHT};
    background: url(${backgroundBottom}) no-repeat center 0;

    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: ${breakpoints.xl}px) {
    margin-bottom: calc(120px + ${BOTTOM_BACKGROUND_HEIGHT});
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    height: auto;
    margin-bottom: calc(60px + ${BOTTOM_BACKGROUND_HEIGHT});
  }
`;

export const IllustrationWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;

  &:after {
    content: '';
    width: 1514px;
    height: 636px;
    position: absolute;
    top: -43px;
    background: url(${illustration}) no-repeat center 0;
    background-size: contain;
    transform: translateX(3px);
  }
`;

export const Image = styled.img`
  max-width: 100%;
  display: block;
  margin-top: -5px;
  position: relative;
  z-index: 2;
  transform: translateX(6.3%);
`;

export const Arrow = styled(ArrowIllustration)`
  width: 65px;
  height: 99px;
  position: absolute;
  top: -56px;

  @media screen and (max-width: ${breakpoints.lg}px) {
    transform: scale(0.6);
    transform-origin: center 0;
  }
`;
